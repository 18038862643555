import { Session, App } from '@caddyshack/common';
import FileSaver from 'file-saver';
import { authApiClient } from '../../app/api/authApi.client';

export const fetchSessions = async (
  searchQuery?: Session.SearchQuery
): Promise<App.Page<Session.SessionDto[]>> => {
  try {
    const response = await authApiClient.post('/sessions/search', searchQuery);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteSessions = async (ids: number[]): Promise<string> => {
  try {
    const { data } = await authApiClient.post('/sessions/delete', {
      ids,
    });

    return data;
  } catch (error) {
    throw error;
  }
};

export const exportSessions = async (
  exportFilters: Session.ExportSessionsFiltersDto
): Promise<void> => {
  try {
    const userId = localStorage.getItem('userId');
    const { data } = await authApiClient.post(
      '/sessions/export',
      { exportFilters, userId },
      {
        responseType: 'arraybuffer',
      }
    );

    const xlsxData = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    FileSaver.saveAs(xlsxData, 'sessions-data.xlsx');
    return data;
  } catch (error) {
    throw error;
  }
};
