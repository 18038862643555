import { createAsyncThunk } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from 'react-google-login';
import { Auth } from '@caddyshack/common';
import withError from '../../app/utils/with-thunk-error';
import {
  login as loginRequest,
  logout as logoutRequest,
  register as registerRequest,
  recoverPassword as recoverPasswordRequest,
  fetchProfile as fetchProfileRequest,
  handleGoogleLoginApi,
  resetPassword as resetPasswordRequest,
  changePassword as changePasswordRequest,
} from '../api/auth.api';
import {
  IRecoverPasswordDataModel,
  ILoginCredentialsModel,
  IRegisterDataModel,
  IProfileModel,
} from '../models/Auth';
import {
  GRIPS,
  FORGOT_PASSWORD_SUCCESS,
  LOGIN,
  REGISTER_SUCCESS,
  RESET_PASSWORD_SUCCESS,
} from '../../app/const/routes';
import {
  openSuccessNotification,
  openWarningNotification,
} from '../../app/store/notifications/notifications.slice';

export const login = createAsyncThunk(
  'auth/login',
  withError(
    async (
      credentials: ILoginCredentialsModel,
      { dispatch }
    ): Promise<string> => {
      const res = await loginRequest(credentials);
      localStorage.setItem('token', res.accessToken);
      localStorage.setItem('userId', res.user.id);

      return dispatch(push(GRIPS));
    }
  )
);

export function instanceOfGoogleLoginResponse(
  object: GoogleLoginResponse | GoogleLoginResponseOffline
): object is GoogleLoginResponse {
  return 'tokenId' in object;
}

export const handleGoogleLogin = createAsyncThunk(
  'auth/google/redirect',
  withError(
    async (
      response: GoogleLoginResponse | GoogleLoginResponseOffline,
      { dispatch }
    ): Promise<void> => {
      if (instanceOfGoogleLoginResponse(response)) {
        const accessToken = await handleGoogleLoginApi(response.tokenId);
        localStorage.setItem('token', accessToken);
        return dispatch(push(GRIPS));
      }
      return dispatch(
        openWarningNotification('Unable to authenticate via Google Auth')
      );
    }
  )
);

export const register = createAsyncThunk(
  'auth/register',
  withError(
    async (data: IRegisterDataModel, { dispatch }): Promise<void> => {
      const response = await registerRequest(data);
      dispatch(push(REGISTER_SUCCESS));
      return response;
    }
  )
);

export const recoverPassword = createAsyncThunk(
  'auth/recoverPassword',
  withError(
    async (
      recoverPasswordData: IRecoverPasswordDataModel,
      { dispatch }
    ): Promise<void> => {
      await recoverPasswordRequest(recoverPasswordData);
      return dispatch(push(FORGOT_PASSWORD_SUCCESS));
    }
  )
);

export const resetPassword = createAsyncThunk(
  'auth/resetPassword',
  withError(
    async (
      resetPasswordData: Auth.ChangePasswordDto,
      { dispatch }
    ): Promise<void> => {
      await resetPasswordRequest(resetPasswordData);
      return dispatch(push(RESET_PASSWORD_SUCCESS));
    }
  )
);

export const changePassword = createAsyncThunk(
  'auth/changePassword',
  withError(
    async (data: Auth.ChangePasswordDto, { dispatch }): Promise<void> => {
      await changePasswordRequest(data);
      dispatch(openSuccessNotification('Password has been changed'));
    }
  )
);

export const fetchProfile = createAsyncThunk(
  'auth/fetchProfile',
  withError(
    async (): Promise<IProfileModel> => {
      return fetchProfileRequest();
    }
  )
);

export const authorize = createAsyncThunk(
  'auth/authorize',
  withError(
    async (
      payload: undefined,
      { dispatch, rejectWithValue }
    ): Promise<IProfileModel> => {
      try {
        return await fetchProfileRequest();
      } catch (e) {
        dispatch(push(LOGIN));
        return rejectWithValue(e.response.data);
      }
    }
  )
);

export const logout = createAsyncThunk(
  'auth/logout',
  withError(
    async (payload: undefined, { dispatch }): Promise<boolean> => {
      await logoutRequest(localStorage.getItem('token'));
      localStorage.removeItem('token');
      dispatch(push(LOGIN));
      return true;
    }
  )
);
